import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { List, fromJS } from 'immutable';
import { useSelector } from 'react-redux';

import { Table, Space, AntDInput as Input, Empty, NewSelect, Skeleton } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import { useDebounce } from '~/hooks/useDebounce';
import { isUserSiteAdmin } from '~/reducers';

import { LinkItemDeleteModal } from '../../../components/LinkItem/LinkItemDeleteModal';
import { LinkStatusRenderer } from '../../../components/LinkItem/LinkStatus';
import { SiteAdminTool } from './SiteAdminTool';
import { SyncNowButtonRenderer } from './SyncNowButton';
import { AutoSyncToggleRenderer } from './AutoSyncToggle';
import { SyncDirectionIconsRenderer } from './SyncDirectionIcons';
import { FlowActionsRenderer } from './FlowActions';
import { LinkInformationsRenderer } from './LinkInformation';
import { useGetFlowListLinks } from './hooks/useGetFlowListLinks';

const desiredKeys = [
  'isAutoSync',
  'A',
  'B',
  'name',
  'isSuspended',
  'kind',
  'syncSettings',
  'state',
  'restricted',
  'syncStatus',
  'user',
  '_id',
];

export const LinkList = ({ linkList }) => {
  const [deleteModalLink, setDeleteModalLink] = useState(fromJS({}));
  const isSiteAdmin = useSelector(isUserSiteAdmin);
  // TODO: once we're ready, have ONE rout (for example /syncs/flows-list) and control the default kind through a query param
  // TODO: setup admin search
  const {
    page,
    setPage,
    setSearch,
    setKinds,
    total,
    loading,
    initializing,
    pageSize,
    kinds,
    searchString,
    setSiteAdminSearch,
    siteAdminSearchString,
  } = useGetFlowListLinks();

  const handleKindChange = (optionKind) => {
    setKinds(optionKind);
  };

  const handlePageChange = (pagination) => {
    setPage(pagination.current - 1);
  };

  const handleSearch = useDebounce((e) => {
    setSearch(e.target.value);
  }, 500);

  const dataSource = linkList
    .map((link) => link.filter((_value, key) => desiredKeys.includes(key)).set('key', link.get('_id')))
    .toJS();

  if (initializing) {
    return <Skeleton />;
  }

  return (
    <>
      {isSiteAdmin && (
        <SiteAdminTool setSiteAdminSearchString={setSiteAdminSearch} siteAdminSearchString={siteAdminSearchString} />
      )}
      <Space style={{ marginBottom: 16 }}>
        <Input.Search onChange={handleSearch} defaultValue={searchString} allowClear />
        <NewSelect
          style={{ minWidth: '100px' }}
          mode="multiple"
          defaultValue={kinds}
          onChange={handleKindChange}
          options={Object.entries(linkTypes.KIND_DISPLAY_NAME).map(([optionKind, displayName]) => ({
            value: optionKind,
            label: displayName,
          }))}
        />
      </Space>
      <Table
        loading={loading}
        locale={{ emptyText: <Empty description="No flows found" /> }}
        pagination={{
          current: page + 1,
          pageSize,
          total,
          hideOnSinglePage: true,
          showTotal: (totalFlows, range) => `${range[0]}-${range[1]} of ${totalFlows} flows`,
        }}
        showHeader={false}
        dataSource={dataSource}
        onChange={handlePageChange}
      >
        <Table.Column title="Auto sync" dataIndex="autoSync" key="autoSync" render={AutoSyncToggleRenderer} />
        <Table.Column title="Tools" dataIndex="sides" onClick key="sides" render={SyncDirectionIconsRenderer} />
        <Table.Column title="Flow Info" dataIndex="flowInfo" key="flowInfo" render={LinkInformationsRenderer} />
        <Table.Column title="Sync health" dataIndex="syncHealth" key="syncHealth" render={LinkStatusRenderer} />
        <Table.Column title="Sync now" dataIndex="syncNow" key="syncNow" render={SyncNowButtonRenderer} />
        <Table.Column
          title="Flow actions"
          dataIndex="flowActions"
          key="flowActions"
          render={(_text, link) => FlowActionsRenderer(_text, link, setDeleteModalLink)}
        />
      </Table>
      <LinkItemDeleteModal
        isOpen={!!deleteModalLink.size}
        sync={deleteModalLink}
        onDeleteSync={() => setDeleteModalLink(fromJS({}))}
        onCancel={() => setDeleteModalLink(fromJS({}))}
        onRequestClose={() => setDeleteModalLink(fromJS({}))}
      />
    </>
  );
};

LinkList.propTypes = {
  linkList: PropTypes.instanceOf(List).isRequired,
  linkFilters: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};
