import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FieldValuesConfigurator, NewTypography } from '@unitoio/mosaic';

import { otherSide } from '~/utils/otherSide';
import { useGetProviderTerms } from '~/containers/FlowBuilder/hooks/useGetProviderTerms';
import { useGetSides } from '../../Rules/hooks';

const EmptyState = () => (
  <>
    <NewTypography.Text strong>Tips: </NewTypography.Text>
    <NewTypography.Text>
      Start simple! Launch your flow without any new actions set. In most cases, the field mapping will cover your
      needs.
    </NewTypography.Text>
  </>
);

export const StartDefaultValues = ({ side: originalSide }) => {
  const { register, watch, setValue, formState, trigger } = useFormContext();

  // we flip the sides, we set default values for B on the A side in the UI
  const side = otherSide(originalSide);

  const actionsPath = `rules.${side}.actions`;
  const formValues = watch(actionsPath);

  const { append, remove } = useFieldArray({
    name: actionsPath,
  });

  const { [side]: currentSide } = useGetSides();
  const { containerName, containerPath, providerName, itemType } = currentSide;
  const itemTerms = useGetProviderTerms(providerName, itemType);

  const handleOnChange = useCallback(
    (name, value) => {
      setValue(name, { trigger: 'start', ...value }, { shouldDirty: true });
      // trigger to validate the field, otherwise is only available on form submit
      trigger(name);
    },
    [setValue, trigger],
  );

  return (
    <FieldValuesConfigurator
      credentialId={currentSide.providerIdentityId}
      containerPath={containerPath}
      containerName={containerName}
      providerName={providerName}
      onChange={handleOnChange}
      itemType={itemType}
      emptyState={<EmptyState />}
      itemTerm={itemTerms.singular ?? itemType}
      control={{
        register,
        name: actionsPath,
        value: formValues,
        remove,
        add: (initialValues = { fieldId: '', value: '' }) => append(initialValues, { shouldDirty: false }),
        errors: formState.errors?.[side]?.actions,
      }}
    />
  );
};

StartDefaultValues.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
};
