import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as routes from '~/consts/routes';

export function useRedirectToPage() {
  const { linkId, workflowId } = useParams();
  const { replace } = useHistory();

  return useCallback(
    (page = routes.FLOW_BUILDER_PAGES.GUIDE, overrideLinkId = linkId) => {
      const baseUrl = workflowId
        ? `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${overrideLinkId}`
        : `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${overrideLinkId}`;

      replace(`${baseUrl}/${page}`);
    },
    [replace, linkId, workflowId],
  );
}
