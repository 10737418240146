import * as unitoIdentityTypes from '~/consts/unitoIdentities';
import * as routes from '~/consts/routes';

export const getUnitoIdentities = (organizationId) => ({
  types: [
    unitoIdentityTypes.GET_UNITO_IDENTITIES_REQUEST,
    unitoIdentityTypes.GET_UNITO_IDENTITIES_SUCCESS,
    unitoIdentityTypes.GET_UNITO_IDENTITIES_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATION_UNITO_IDENTITIES(organizationId),
  meta: { organizationId },
});

export const mergeUnitoIdentities = (organizationId, unitoIdentityIds, isExcluded) => ({
  types: [
    unitoIdentityTypes.MERGE_UNITO_IDENTITIES_REQUEST,
    unitoIdentityTypes.MERGE_UNITO_IDENTITIES_SUCCESS,
    unitoIdentityTypes.MERGE_UNITO_IDENTITIES_FAILURE,
  ],
  method: routes.METHODS.POST,
  url: routes.API_PATHS.MERGE_UNITO_IDENTITIES(organizationId),
  payload: { unitoIdentityIds, isExcluded },
  meta: { organizationId, unitoIdentityIds, isExcluded },
});

export const unmergeUnitoIdentity = (organizationId, unitoIdentityId, providerIdentityIdsToUnmerge, isExcluded) => ({
  types: [
    unitoIdentityTypes.UNMERGE_UNITO_IDENTITIES_REQUEST,
    unitoIdentityTypes.UNMERGE_UNITO_IDENTITIES_SUCCESS,
    unitoIdentityTypes.UNMERGE_UNITO_IDENTITIES_FAILURE,
  ],
  method: routes.METHODS.POST,
  url: routes.API_PATHS.UNMERGE_UNITO_IDENTITIES(organizationId, unitoIdentityId),
  payload: { providerIdentityIds: providerIdentityIdsToUnmerge, isExcluded },
  meta: { organizationId, unitoIdentityId, providerIdentityIdsToUnmerge, isExcluded },
});

export const updateUnitoIdentity = (organizationId, unitoIdentityId, isExcluded) => ({
  types: [
    unitoIdentityTypes.MERGE_UNITO_IDENTITIES_REQUEST,
    unitoIdentityTypes.MERGE_UNITO_IDENTITIES_SUCCESS,
    unitoIdentityTypes.MERGE_UNITO_IDENTITIES_FAILURE,
  ],
  method: routes.METHODS.PATCH,
  url: routes.API_PATHS.UPDATE_UNITO_IDENTITIES(organizationId, unitoIdentityId),
  payload: { isExcluded },
  meta: { organizationId, unitoIdentityId, isExcluded },
});
