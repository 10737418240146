import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';

import { useFetchRequiredActions } from '@unitoio/mosaic';

import { getContainerById, getFeatureFlagValue } from '~/reducers';
import * as featureTypes from '~/consts/features';
import * as linkTypes from '~/consts/link';
import * as fieldTypes from '~/consts/fields';

export function useSetRequiredActions(side) {
  const [providerIdentityId, containerId, itemType, linkState, currentSideActions, syncDirection] = useWatch({
    name: [
      `${side}.providerIdentityId`,
      `${side}.containerId`,
      `${side}.itemType`,
      'state',
      `rules.${side}.actions`,
      'syncDirection',
    ],
  });
  const { setValue } = useFormContext();

  const usesModernRules = useSelector((state) => getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE));
  const container = useSelector((state) => getContainerById(state, providerIdentityId, containerId));
  const containerPath = container?.get('path');

  const { isFetchingFields, requiredActions } = useFetchRequiredActions({
    credentialId: providerIdentityId,
    containerPath,
    itemType,
  });

  // if this hook is called with side A and syncDirection is B
  // it means A is readOnly, we don't want to set required actions for it
  const isSideReadOnly = side !== syncDirection;

  const updateActions = useCallback(
    (values) => {
      setValue(
        `rules.${side}.actions`,
        values.map((action) => ({ ...action, trigger: 'start' })),
        { shouldDirty: true },
      );
    },
    [setValue, side],
  );

  const isDraft = linkState === linkTypes.LINK_STATES.DRAFT;

  const shouldSetRequiredActions =
    usesModernRules &&
    !isFetchingFields &&
    isDraft &&
    // if flow is 1-way, only set required actions for the non readOnly side
    (!isSideReadOnly || syncDirection === fieldTypes.TARGET.BOTH) &&
    requiredActions.length &&
    !requiredActions.every((requiredAction) =>
      currentSideActions.some((action) => action.fieldName === requiredAction.fieldName),
    );

  useEffect(() => {
    if (shouldSetRequiredActions) {
      updateActions(requiredActions);
    }
  }, [shouldSetRequiredActions, updateActions]);

  return { isFetchingFields };
}
