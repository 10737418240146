import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { useHistory } from 'react-router-dom';

import { Icon, Dropdown } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';

export const FlowActions = ({ link, deleteCallback }) => {
  const history = useHistory();
  const editFlowRoute = routes.getEditFlowBuilderRoute(link.key);
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'edit',
            label: 'Edit',
            onClick: () => {
              history.push(editFlowRoute);
            },
          },
          {
            key: 'duplicate',
            label: 'Duplicate',
            onClick: () => {
              history.push(routes.getDuplicateFlowBuilderRoute(link.key));
            },
          },
          {
            key: 'delete',
            label: 'Delete',
            onClick: () => {
              deleteCallback(fromJS(link));
            },
          },
        ],
      }}
    >
      <Icon name="ellipsis" data-testid={`flow-actions-dropdown-${link.key}`} />
    </Dropdown>
  );
};

// abstraction from the antD specs for a table render
export const FlowActionsRenderer = (_text, link, deleteCallback) => (
  <FlowActions link={link} deleteCallback={deleteCallback} />
);

FlowActions.propTypes = {
  link: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  deleteCallback: PropTypes.func.isRequired,
};
