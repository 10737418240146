import { useMemo, useEffect } from 'react';
import { useSortBy, useFilters, useTable, usePagination, useAsyncDebounce } from 'react-table';

/**
 * Used to create Data Tables.
 *
 * useDataTable takes care of the logic and interactions between a paginator, a table, and the component they are in.
 */
export function useDataTable({
  data,
  columns, // As defined by react-table, see https://react-table.tanstack.com/
  initialSortState = [], // Initial columns we'll sort by, see "useSortBy" in the react-table documentation
  fetchData, // Function to call for fetching data in the form of (page, pageSize, filters, sort) => void;
  pageSize = 20,
  currentPage = 0,
  recordsCount = 0, // How many records in total exist. Important for pagination purposes.
  sortHeaderRenderer: renderHeaderSort = () => null, // Function that renders the sort state, to append next to the header. Could be an upwards or a downwards arrow for example
  disableMultiSort = true,
  manualPagination = true,
  manualSortBy = true,
  manualFilters = true,
  urlSearch,
}) {
  const tableInstance = useTable(
    {
      data: useMemo(() => data, [data]),
      columns: useMemo(() => columns, [columns]),
      pageCount: useMemo(() => Math.ceil(recordsCount / pageSize), [recordsCount, pageSize]),
      disableMultiSort,
      manualPagination,
      manualSortBy,
      manualFilters,
      initialState: useMemo(
        () => ({
          pageSize,
          sortBy: initialSortState,
        }),
        [initialSortState, pageSize],
      ),
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize: statePageSize },
  } = tableInstance;

  const debouncedFetchData = useAsyncDebounce(async (...args) => {
    try {
      await fetchData(...args);
    } catch {
      // Error already being handled in fetchData
      // Must add catch because in fetchData we do a reject promise which would throw an error.
    }
  }, 200);

  const { filters = {}, sortBy } = tableInstance.state;

  // Used to set the page index from outside the data table
  useEffect(() => {
    gotoPage(currentPage);
  }, [currentPage, gotoPage]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [setPageSize, pageSize]);

  useEffect(() => {
    debouncedFetchData(pageIndex, statePageSize, urlSearch, filters, sortBy);
  }, [pageIndex, statePageSize, filters, sortBy, debouncedFetchData, urlSearch]);

  const dataTableData = useMemo(
    () => ({
      tableInstance,
      gotoPage,
      pageSize: statePageSize,
      renderHeaderSort,
      currentPage: pageIndex,
      totalRecords: recordsCount,
    }),
    [tableInstance, gotoPage, statePageSize, renderHeaderSort, pageIndex, recordsCount],
  );

  return dataTableData;
}
