import * as organizationActions from '~/actions/organizations';
import * as billingTypes from '~/consts/billing';
import * as routes from '~/consts/routes';
import {
  getOrganizationSubscriptionId,
  getOrganizationCustomerId,
  getSelectedOrganizationId,
  getPlans as getPlansSelector,
} from 'reducers';

export const getOrganization =
  (orgId, options = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = orgId || getSelectedOrganizationId(state);

    return dispatch({
      ...options,
      types: [
        billingTypes.GET_BILLING_ORGANIZATION_REQUEST,
        billingTypes.GET_BILLING_ORGANIZATION_SUCCESS,
        billingTypes.GET_BILLING_ORGANIZATION_FAILURE,
      ],
      url: routes.API_PATHS.GET_BILLING_ORGANIZATION(organizationId),
    });
  };

export const getPlans = (organizationId) => (dispatch, getState) => {
  const orgId = organizationId || getSelectedOrganizationId(getState());
  return dispatch({
    types: [billingTypes.GET_PLANS_REQUEST, billingTypes.GET_PLANS_SUCCESS, billingTypes.GET_PLANS_FAILURE],
    url: routes.API_PATHS.GET_PLANS(orgId),
  });
};

export const getOrganizationPlanProfile = (organizationId) => (dispatch, getState) => {
  const state = getState();
  const orgId = organizationId || getSelectedOrganizationId(state);
  return dispatch({
    types: [
      billingTypes.GET_ORGANIZATION_PLAN_PROFILE_REQUEST,
      billingTypes.GET_ORGANIZATION_PLAN_PROFILE_SUCCESS,
      billingTypes.GET_ORGANIZATION_PLAN_PROFILE_FAILURE,
    ],
    meta: { organizationId: orgId },
    url: routes.API_PATHS.GET_ORGANIZATION_PLAN_PROFILE(orgId),
  });
};

export const getPlanRejectionReasons = (organizationId, planId) => (dispatch, getState) => {
  const state = getState();
  const plans = getPlansSelector(state);
  const orgId = organizationId || getSelectedOrganizationId(getState());
  const selectablePlanIds = plans.filter((plan) => plan.get('id') !== planId).keySeq();

  return dispatch({
    types: [
      billingTypes.GET_PLAN_REJECTION_REASONS_REQUEST,
      billingTypes.GET_PLAN_REJECTION_REASONS_SUCCESS,
      billingTypes.GET_PLAN_REJECTION_REASONS_FAILURE,
    ],
    method: routes.METHODS.POST,
    payload: { planIds: selectablePlanIds },
    url: routes.API_PATHS.GET_PLAN_REJECTION_REASONS(orgId),
  });
};

export const getOrganizationInvoices = (organizationId) => ({
  types: [
    billingTypes.GET_ORGANIZATION_INVOICES_REQUEST,
    billingTypes.GET_ORGANIZATION_INVOICES_SUCCESS,
    billingTypes.GET_ORGANIZATION_INVOICES_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATION_INVOICES(organizationId),
});

export const updateSubscription = (organizationId, planId, stripeToken) => async (dispatch, getState) => {
  const state = getState();
  const customerId = getOrganizationCustomerId(state, organizationId);
  const subscriptionId = getOrganizationSubscriptionId(state, organizationId);

  const response = await dispatch({
    types: [
      billingTypes.UPDATE_SUBSCRIPTION_REQUEST,
      billingTypes.UPDATE_SUBSCRIPTION_SUCCESS,
      billingTypes.UPDATE_SUBSCRIPTION_FAILURE,
    ],
    payload: !subscriptionId
      ? { action: 'change', planId, source: stripeToken, customerId }
      : { action: 'change', planId, source: stripeToken },
    method: !subscriptionId ? routes.METHODS.POST : routes.METHODS.PUT,
    url: !subscriptionId ? routes.API_PATHS.CREATE_SUBSCRIPTION : routes.API_PATHS.UPDATE_SUBSCRIPTION(subscriptionId),
  });

  await Promise.all([dispatch(getOrganizationInvoices(organizationId)), dispatch(getOrganizationPlanProfile())]);

  dispatch(organizationActions.getFlags(organizationId));
  return response;
};

export const cancelSubscription =
  (organizationId, cancelationFeedback, qualitativeFeedback) => (dispatch, getState) => {
    const state = getState();
    const subscriptionId = getOrganizationSubscriptionId(state, organizationId);

    return dispatch({
      types: [
        billingTypes.CANCEL_SUBSCRIPTION_REQUEST,
        billingTypes.CANCEL_SUBSCRIPTION_SUCCESS,
        billingTypes.CANCEL_SUBSCRIPTION_FAILURE,
      ],
      method: routes.METHODS.DELETE,
      url: routes.API_PATHS.CANCEL_SUBSCRIPTION(subscriptionId),
      payload: {
        cancelationFeedback,
        qualitativeFeedback,
      },
    });
  };

export const pauseSubscription = (organizationId, pauseFeedback, pauseQualitativeFeedback) => (dispatch, getState) => {
  const state = getState();
  const subscriptionId = getOrganizationSubscriptionId(state, organizationId);

  return dispatch({
    types: [
      billingTypes.PAUSE_SUBSCRIPTION_REQUEST,
      billingTypes.PAUSE_SUBSCRIPTION_SUCCESS,
      billingTypes.PAUSE_SUBSCRIPTION_FAILURE,
    ],
    method: routes.METHODS.PUT,
    url: routes.API_PATHS.UPDATE_SUBSCRIPTION(subscriptionId),
    payload: {
      action: 'pause',
      pauseFeedback,
      pauseQualitativeFeedback,
    },
  });
};

export const unpauseSubscription = (organizationId) => (dispatch, getState) => {
  const state = getState();
  const subscriptionId = getOrganizationSubscriptionId(state, organizationId);

  return dispatch({
    types: [
      billingTypes.UNPAUSE_SUBSCRIPTION_REQUEST,
      billingTypes.UNPAUSE_SUBSCRIPTION_SUCCESS,
      billingTypes.UNPAUSE_SUBSCRIPTION_FAILURE,
    ],
    method: routes.METHODS.PUT,
    url: routes.API_PATHS.UPDATE_SUBSCRIPTION(subscriptionId),
    payload: { action: 'unpause' },
  });
};

export const editOrganization = (payload) => async (dispatch, getState) => {
  const state = getState();
  const organizationId = getSelectedOrganizationId(state);

  return dispatch({
    types: [
      billingTypes.UPDATE_BILLING_ORGANIZATION_REQUEST,
      billingTypes.UPDATE_BILLING_ORGANIZATION_SUCCESS,
      billingTypes.UPDATE_BILLING_ORGANIZATION_FAILURE,
    ],
    method: routes.METHODS.PUT,
    url: routes.API_PATHS.UPDATE_BILLING_ORGANIZATION(organizationId),
    payload,
  });
};
