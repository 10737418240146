/* eslint-disable no-template-curly-in-string */
const TEMPLATES = {
  MissingField: {
    level: 'warning',
    label:
      'A ${field.term.singular} may have been removed from ${container} ${container.term.singular} in ${provider}. This may prevent associated ${task.term.plural} from syncing properly.',
    solutions: {
      title: 'Solutions',
      context: [
        'Remove the “Not found” ${field.term.singular} in your mappings.',
        'Re-create the ${field.term.singular} in ${container} ${container.term.singular} in ${provider}.',
        'Update your mappings with the new ${field.term.singular} accordingly.',
        'Once done, save and sync.',
      ],
      alternative: [
        'If the ${field.term.singular} with ID ${field.id} was removed intentionally, you can dismiss this warning by just removing it in your mappings.',
      ],
    },
    flowBuilderSolutions: {
      title: 'If you want to keep this ${pageItem.singular}:',
      context: [
        'Recreate the ${field.term.singular} in ${container} ${container.term.singular} in ${provider}.',
        'Refresh your page and update your ${pageItem.plural} with the new ${field.term.singular} accordingly.',
        'Once done, save and sync.',
      ],
      alternative: [
        'If the ${field.term.singular} with ID ${field.id} was removed intentionally, you can dismiss this warning by just removing your ${pageItem.singular}.',
      ],
    },
  },
  MissingRequiredField: {
    level: 'error',
    label:
      'A ${field.term.singular} may have been removed from the ${container} ${container.term.singular} in ${provider}. This will prevent associated ${task.term.plural} from syncing properly.',
    solutions: {
      title: 'Solutions',
      context: ['Recreate the ${field.term.singular} in the ${container} ${container.term.singular} in ${provider}.'],
    },
  },
  MissingTimestampField: {
    level: 'warning',
    label:
      'A timestamp ${field.term.singular} may have been removed from ${container} ${container.term.singular} in ${provider}. This will affect the performance of your flow.',
    solutions: {
      title: 'Solutions',
      context: [
        'Add the ${field.displayName} ${field.term.singular} in ${container} ${container.term.singular} in ${provider}.',
      ],
    },
  },
  ReadonlyField: {
    level: 'warning',
    label:
      "It looks like you're trying to sync changes to the ${field.term.singular} in the ${provider} ${container} ${container.term.singular}. Unfortunately, there is an issue due to ${provider}’s read only rules on certain ${field.term.singular}.",
    solutions: {
      title: 'Solutions',
      context: [
        'Edit the ${field.term.singular} type in the ${provider} ${container} ${container.term.singular} to make it editable.',
      ],
      alternative: [
        'If the change was synced unintentionally, you can ignore this warning and it will disappear on its own.',
      ],
    },
  },
  ProjectInaccessible: {
    level: 'error',
    label:
      'Looks like you don’t have the required access to the ${container} ${container.term.singular} in ${provider}.',
    solutions: {
      title: 'Solutions',
      context: [
        'Get access to ${container} ${container.term.singular} for ${profileDisplayName} from your admin.',
        'Once done, try to sync here. ${syncNowButton}',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solutions',
      context: [
        'Get access to ${container} ${container.term.singular} for ${profileDisplayName} from your admin.',
        'Once done, click on "Sync Now".',
      ],
    },
  },
  InvalidAccessToken: {
    level: 'error',
    label: 'We have lost access to your ${provider} via ${profileDisplayName}.',
    solutions: {
      title: 'Solutions',
      context: [
        'Try reconnecting ${provider} via ${profileDisplayName} from the ${connectorsPageLink}.',
        'Then, try syncing it here. ${syncNowButton}',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solutions',
      context: [
        'Try reconnecting ${provider} via ${profileDisplayName} from the ${connectorsPageLink}.',
        'Once done, try to sync by clicking on "Sync Now".',
      ],
    },
  },
  ToolInaccessible: {
    level: 'error',
    label: 'We have lost access to your ${provider} via ${profileDisplayName}.',
    solutions: {
      title: 'Solutions',
      context: [
        'Get access to ${provider} via ${profileDisplayName} from your admin.',
        'Once done, try to sync here. ${syncNowButton}',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solutions',
      context: [
        'Get access to ${provider} via ${profileDisplayName} from your admin.',
        'Once done, try to sync by clicking on "Sync Now".',
      ],
    },
  },
  SubTaskDisabled: {
    level: 'warning',
    label:
      "Looks like you're trying to sync subtasks with Unito while it is disabled in ${provider} ${container} ${container.term.singular}.",
    solutions: {
      title: 'Solutions',
      context: [
        'Enable ${provider} subtasks in ${container} ${container.term.singular}.',
        'Or, disable syncing subtasks in the <bold>More options</bold> tab.',
        'Once done, save and sync.',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solutions',
      context: [
        'Enable ${provider} subtasks in ${container} ${container.term.singular}.',
        'Or, remove the subtask field association on this page.',
        'Once done, save your changes. Then, click on "Sync Now".',
      ],
    },
  },
  SubTaskInaccessible: {
    level: 'warning',
    label:
      'Looks like you don’t have access to ${provider} subtasks in the ${container.name} ${container.term.singular}.',
    solutions: {
      title: 'Solutions',
      context: [
        'Get access to subtasks in ${container} for ${profileDisplayName} from your ${provider} admin.',
        'Or, disable syncing subtasks in the <bold>More options</bold> tab.',
        'Once done, save and sync.',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solutions',
      context: [
        'Enable ${provider} subtasks in ${container} ${container.term.singular}.',
        'Or, remove the subtask field association on this page.',
        'Once done, save your changes. Then, click on "Sync Now".',
      ],
    },
  },
  TaskInaccessible: {
    level: 'error',
    label:
      'Looks like you don’t have access to create or update ${provider} ${task.term.plural} in ${container} ${container.term.singular}',
    solutions: {
      title: 'Solutions',
      context: [
        'Get the required access to create and update ${task.term.plural} in ${container} ${container.term.singular} for ${profileDisplayName} from your ${provider} admin.',
        'Once done, try to sync here. ${syncNowButton}',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solutions',
      context: [
        'Get the required access to create and update ${task.term.plural} in ${container} ${container.term.singular} for ${profileDisplayName} from your ${provider} admin.',
        'Once done, click on "Sync Now".',
      ],
    },
  },
  ToolTimeout: {
    level: 'error',
    label: 'Looks like ${provider} is taking a bit longer to respond. ${contactSupportTeamButton}.',
  },
  ToolSlow: {
    level: 'warning',
    label:
      'Your ${provider} instance is responding slower than expected for this flow. This can noticeably affect the flow speed.',
    solutions: {
      title: 'Possible solutions',
      context: [
        'Consider re-indexing your ${provider} projects.',
        'Inform your ${provider} admin about slow response times from this URL (${pageUrl}).',
        'Learn more about troubleshooting Jira server performance issues ${helpArticleUrl}.',
      ],
    },
  },
  ProjectNotFound: {
    level: 'error',
    label:
      "Looks like we couldn't find ${container} ${container.term.singular}, it may have been removed by someone on your team.",
    solutions: {
      title: 'Solutions',
      context: [
        'To continue syncing, restore your ${container} ${container.term.singular} and click on "Sync now".',
        "If ${container} ${container.term.singular} was removed intentionally and you don't want to sync it anymore, you may delete this flow.",
      ],
    },
  },
  TaskInWrongContainer: {
    level: 'warning',
    label:
      'During our sync, we found matching ${task.term.plural} in the same ${container.term.singular} that may have been manually moved there or duplicated through another automation service. To avoid issues, the affected ${task.term.singular} ${movedTaskUrl} will stop syncing.',
    solutions: {
      title: 'Solutions',
      context: [
        'Please move this ${task.term.singular} back to its original ${container.term.singular}.',
        'Once the ${task.term.singular} has been moved back, we will start syncing it again within a few minutes.',
        'If this task was moved intentionally, please reach out to us to help you resolve this issue.',
      ],
    },
  },
  EmptyRequiredField: {
    level: 'warning',
    label:
      'Looks like some ${task.term.plural} don’t have ${field.displayName} in ${container} in ${provider}. This might prevent them from syncing.',
    solutions: {
      title: 'Solution',
      context: ['Go to ${container} and add information in those empty ${task.term.plural}.'],
    },
  },
  MissingMappedField: {
    level: 'warning',
    label:
      'Looks like the field mapped to ${provider} ${field.displayName} has been removed from ${container} in ${deletedFieldProvider}. This may prevent work from syncing properly.',
    solutions: {
      title: 'Solution',
      context: [
        'Go to the field mappings page and remove the mapping where the field is "not found".',
        'Remap ${provider} ${field.displayName} with the field of your choice in ${deletedFieldProvider}.',
        'Once done, save your changes.',
      ],
    },
    flowBuilderSolutions: {
      title: 'Solution',
      context: [
        'Remap ${provider} ${field.displayName} with the field of your choice in ${deletedFieldProvider}.',
        'Or, remove the field association with the missing field.',
        'Once done, save and sync.',
      ],
    },
  },
  IncompatibleFilterType: {
    level: 'error',
    label:
      'The existing rules are not compatible with ${field.term.singular} ${fieldName}. Please set a compatible rule.',
    flowBuilderSolutions: {
      title: 'If you want to fix this ${pageItem.singular}:',
      context: [
        'Recreate the ${pageItem.singular} for ${field.term.singular} ${fieldName} with a compatible rule.',
        'Once done, save and sync.',
      ],
    },
  },
  DuplicateItemCount: {
    level: 'warning',
    label:
      'During our sync, we found matching ${task.term.plural} in the same ${container.term.singular} that would create a conflict if they were included. To avoid issues, the affected ${task.term.plural} have not synced.',
    solutions: {
      title: 'Solution',
      context: [
        "Use your tool's native features to identify and merge duplicate ${task.term.plural}. We've collected specific instructions for different tools in this article.",
        'Once the affected ${container.term.singular} has been sanitized, click Sync Now to re-identify the ${task.term.plural} and sync them.',
        'If you are still having trouble with this issue, please reach out to us to help you resolve it.',
      ],
    },
  },
  MappedFieldValueNotAccepted: {
    level: 'error',
    label:
      'A discrepancy in the mapping containing ${provider} ${field.displayName} is causing an issue with this ${taskTermWithURL}.',
    solutions: {
      title: 'Solutions',
      context: [
        'Verify that these mapped fields contain similar information and selectable values.',
        'Once done, click on "Sync Now".',
        'Or, remove this mapping, save the flow and click sync now.',
      ],
    },
  },
};

export { TEMPLATES as errorTemplates };
