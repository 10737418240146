import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import { WorkBlockPortModel } from './WorkBlockPortModel';

export class WorkBlockPortFactory extends AbstractReactFactory {
  constructor() {
    super('WorkBlockPort');
  }

  generateModel = () => new WorkBlockPortModel();

  generateReactWidget() {
    return null;
  }
}
