import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Typography, tokens, Select, NewTooltip, Icon } from '@unitoio/mosaic';
import { getEmbedName } from 'reducers';
import * as appActions from '~/actions/app';
import * as appTypes from '~/consts/app';

export const EmbedOverride = () => {
  const embedName = useSelector((state) => getEmbedName(state));
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const toSend = data === 'clear' ? '' : data;
    dispatch(appActions.updateEmbedLocally(toSend));
  };

  const options = [
    { value: 'clear', label: 'No embed' },
    ...Object.values(appTypes.EMBED).map((embedValue) => ({ value: embedValue, label: embedValue })),
  ];

  return (
    <Box m={[tokens.spacing.s4, 0, 0, 0]}>
      <Typography variant="h3">
        Override embed state
        <NewTooltip title="This will only affect your current session. The change will be wiped when you refresh the page!">
          <Icon
            style={{ marginLeft: tokens.spacing.s2 }}
            size="xs"
            name="info-circle"
            color={tokens.colors.content.info.default}
          />
        </NewTooltip>
      </Typography>

      <div>
        <Box alignItems="center" m={[0, 0, tokens.spacing.s3, 0]}>
          <Select onChange={onSubmit} placeholder="Embed value" value={embedName} options={options} />
        </Box>
      </div>
    </Box>
  );
};
