import React, { useContext, useEffect, useState, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Alert, Box, Button, tokens } from '@unitoio/mosaic';

import * as fieldTypes from '~/consts/fields';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { FlowBuilderErrorContext } from '~/contexts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { Href } from '~/components/Href/Href';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

import { getAreModernRulesEnabled } from '../../utils/getAreModernRulesEnabled';
import * as formUtils from '../../utils/form';
import { PageContainer } from '../PageContainer/PageContainer';
import { PageHeader } from '../../components/PageHeader';
import { ManageDuplicates } from '../../components/ManageDuplicates';
import { useProviderItemSupportsMerging } from '../../hooks/useProviderItemSupportsMerging';
import { useGetContainerTypes } from '../../hooks/useGetContainerTypes';
import { useAreBothSidesMergeable } from '../../hooks/useAreBothSidesMergeable';
import { DuplicateBanner } from '../../components/DuplicateBanner';
import { RuleSidePanel } from '../SidePanels/RuleSidePanel';
import { MergeDuplicatesWarning } from './MergeDuplicatesWarning/MergeDuplicatesWarning';
import { useGetSides, useSetSyncDirection } from './hooks';
import { RulesSection } from './RulesSection/RulesSection';
import { useGetProviderNames } from '../../hooks/useGetProviderNames';
import { useInitializeLegacyDefaultValues } from '../../hooks/useInitializeLegacyDefaultValues';
import { statuses } from './consts';
import { useRedirectToPage } from '../../hooks/useRedirectToPage';

const OpenSidePanelLink = styled.span`
  color: ${tokens.colors.content.info.default};
  cursor: pointer;
`;

export const Rules = ({ loadingState, match }) => {
  const { linkId } = match.params;
  const linkState = useWatch({ name: 'state' });
  const isDraftFlow = linkId && linkState === linkTypes.LINK_STATES.DRAFT;
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const redirectToPage = useRedirectToPage();
  const { initializeFieldsLoadingState } = useInitializeLegacyDefaultValues({ loadingState });

  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [containerTypeA, containerTypeB] = useGetContainerTypes();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const [containerA, containerB] = useGetContainers();
  const containerIdA = containerA.get('id');
  const containerIdB = containerB.get('id');

  const sides = useGetSides();

  const pageName = useContext(FlowBuilderErrorContext);
  const isLoading = [statuses.INITIAL, statuses.LOADING, statuses.STANDBY].includes(initializeFieldsLoadingState);

  // If we find a merge missing settings error, ignore it in this component (to not block the CONFIRM)
  // we want to allow users to keep editing their drafts freely, instead the LaunchFlow modal will
  // remind users to set this value.
  const { useMergeMissingSettings, ...otherErrors } = errors[pageName] || {};
  const hasRulesErrors = !!Object.keys(otherErrors)?.length;
  const shouldConfirmButtonBeDisabled =
    isLoading ||
    hasRulesErrors ||
    [formUtils.loadingStates.LOADING, formUtils.loadingStates.SAVING].includes(loadingState);

  // previously known as contact based
  const providerItemASupportsMerge = useProviderItemSupportsMerging(providerNameA, itemTypeA);
  const providerItemBSupportsMerge = useProviderItemSupportsMerging(providerNameB, itemTypeB);
  const oneItemSupportsMerge = providerItemASupportsMerge || providerItemBSupportsMerge;

  const showSyncDirectionAlert = useSetSyncDirection({ providerNameA, providerNameB, watch, setValue });
  const bothSidesMergeable = useAreBothSidesMergeable({ itemTypeA, itemTypeB, providerNameA, providerNameB });

  const isEarliestCreatedAtFieldDisplayed =
    sides.A.fields.some((field) => field.fieldId === fieldTypes.EARLIEST_CREATED_AT) ||
    sides.B.fields.some((field) => field.fieldId === fieldTypes.EARLIEST_CREATED_AT);

  const initialIsEarliestCreatedAtFieldDisplayedRef = useRef(isEarliestCreatedAtFieldDisplayed);

  const areModernRulesEnabled = getAreModernRulesEnabled(sides.A) || getAreModernRulesEnabled(sides.B);
  const trackEvent = useTrackEvent({ selected_tool_names: `${providerNameA}, ${providerNameB}` });
  useEffect(() => {
    if (initialIsEarliestCreatedAtFieldDisplayedRef.current) {
      trackEvent(trackingTypes.START, {
        limit: 'test mode',
      });
      // since the tracking for modern rules happens in Mosaic, we need to skip it here to avoid double events
    } else if (!areModernRulesEnabled) {
      trackEvent(trackingTypes.START);
    }
  }, [trackEvent, areModernRulesEnabled]);

  const handleOnSubmitClick = () => {
    trackEvent(trackingTypes.SUBMIT);
    redirectToPage(routes.FLOW_BUILDER_PAGES.MAPPINGS);
  };

  const directionValue = watch('syncDirection');

  const getPluralProviderTerms = (shouldCapitalize = false) => (
    <ProviderTermsByName
      providerNameA={providerNameA}
      providerNameB={providerNameB}
      plurality="plural"
      termKey="task"
      pcdv3
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
      containerTypeA={containerTypeA}
      containerTypeB={containerTypeB}
      containerIdA={containerIdA}
      containerIdB={containerIdB}
      textTransform={shouldCapitalize ? 'capitalize' : undefined}
    />
  );

  const soloRuleSection = directionValue === fieldTypes.TARGET.A ? { B: sides.B } : { A: sides.A };
  const ruleSections =
    directionValue === fieldTypes.TARGET.BOTH ? Object.entries(sides) : Object.entries(soloRuleSection);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  return (
    <PageContainer>
      <PageHeader
        title={<>Select the {getPluralProviderTerms()} you want to sync</>}
        subtitle={
          <>
            Add rules to restrict which {getPluralProviderTerms()} Unito syncs. {getPluralProviderTerms(true)} must
            always meet the rules you create to continue syncing.{' '}
            <OpenSidePanelLink
              onClick={() => {
                setIsSidePanelOpen(true);
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.NEED_HELP,
                  selected_tool_name: `${providerNameA},${providerNameB}`,
                });
              }}
            >
              Need some help setting up your rules?
            </OpenSidePanelLink>
            <RuleSidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)} linkId={linkId} />
          </>
        }
      />
      <DuplicateBanner />

      {showSyncDirectionAlert && (
        <Box m={[tokens.spacing.s4, 0]}>
          <Alert size="sm">
            With the tools and the rules you have chosen, you can only do one-way flows. To learn more, see{' '}
            <Href href="https://guide.unito.io/how-to-pick-the-best-block-of-work-for-your-use-case">
              how to choose the best block of work
            </Href>{' '}
            for your use case.
          </Alert>
        </Box>
      )}

      {!isLoading && bothSidesMergeable && <ManageDuplicates />}

      {ruleSections.map(([side]) => (
        <RulesSection
          key={`rules-section-${side}`}
          side={side}
          sides={sides}
          linkState={linkState}
          isLoading={isLoading}
          isAutoSaving={loadingState === formUtils.loadingStates.SAVING}
        />
      ))}

      {oneItemSupportsMerge && !bothSidesMergeable && <MergeDuplicatesWarning />}

      {isDraftFlow && (
        <Box justifyContent="flex-end" m={[tokens.spacing.s7, 0, 0, 0]}>
          <Button
            type="submit"
            disabled={shouldConfirmButtonBeDisabled}
            loading={loadingState === formUtils.loadingStates.SAVING}
            onClick={handleOnSubmitClick}
          >
            Confirm
          </Button>
        </Box>
      )}
    </PageContainer>
  );
};

Rules.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ linkId: PropTypes.string }).isRequired,
  }).isRequired,
};
