import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button, Flex, Skeleton, Space, tokens } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { PageContainer } from '../PageContainer/PageContainer';
import { ModernRulesHeader } from './ModernRulesHeader';
import { ModernRuleSide } from './ModernRuleSide';
import { statuses } from '../Rules/consts';
import * as formUtils from '../../utils/form';
import { ManageDuplicates } from '../../components/ManageDuplicates';
import { useSetRequiredActions } from './hooks/useSetRequiredActions';
import { useRedirectToPage } from '../../hooks/useRedirectToPage';
import { useTrackEvent } from '~/hooks';

// TODO: the loading state is tied to the flow builder master hook, can't extract it easily so still drilling it down
export const ModernRules = ({ loadingState }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const isLoading = [statuses.INITIAL, statuses.LOADING, statuses.STANDBY].includes(loadingState);
  const currentLinkState = useWatch({ name: 'state' });
  const isDraftFlow = currentLinkState === linkTypes.LINK_STATES.DRAFT;
  const hasErrors = errors?.A?.actions || errors?.B?.actions;
  const redirectToPage = useRedirectToPage();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);

  const handleOnSubmitClick = () => {
    trackEvent(trackingTypes.SUBMIT);
    redirectToPage(routes.FLOW_BUILDER_PAGES.MAPPINGS);
  };

  // Note: ideally we'd move the useSetRequiredActions hooks to useFlowBuilderGetForm
  // but because of the current amount of re-renders at the flow level,
  // we're keeping it in ModernRules for now.
  useSetRequiredActions('A');
  useSetRequiredActions('B');

  return (
    <PageContainer>
      <ModernRulesHeader />

      <Skeleton loading={isLoading}>
        <Space style={{ marginBottom: tokens.spacing.s4 }}>
          <ManageDuplicates />
        </Space>
        <ModernRuleSide side="A" loadingState={loadingState} />
        <ModernRuleSide side="B" loadingState={loadingState} />
      </Skeleton>
      {isDraftFlow && (
        <Flex justify="flex-end">
          <Button
            disabled={hasErrors}
            type="submit"
            loading={loadingState === formUtils.loadingStates.SAVING}
            onClick={handleOnSubmitClick}
          >
            Confirm
          </Button>
        </Flex>
      )}
    </PageContainer>
  );
};

ModernRules.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
