import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { NewAlert as Alert, NewAlertLevel, NewTypography } from '@unitoio/mosaic';

import { getProviderByName } from '~/reducers';
import * as routes from '~/consts/routes';

export const IncompleteActionsAlert = ({ providerName }) => {
  const match = useRouteMatch();
  const { url } = match;
  const redirectUrl = url.includes(`/${routes.FLOW_BUILDER_PAGES.GUIDE}`)
    ? url.replace(`/${routes.FLOW_BUILDER_PAGES.GUIDE}`, `/${routes.FLOW_BUILDER_PAGES.RULES}`)
    : `${url}/${routes.FLOW_BUILDER_PAGES.RULES}`;
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const providerDisplayName = provider.get('displayName') ?? providerName;
  return (
    <Alert
      level={NewAlertLevel.WARNING}
      message={
        <NewTypography.Text>
          Some actions in your rules are incomplete for {providerDisplayName}, please{' '}
          <Link to={redirectUrl}>review</Link> them before launching your flow.
        </NewTypography.Text>
      }
    />
  );
};

IncompleteActionsAlert.propTypes = {
  providerName: PropTypes.string,
};
