import { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import * as loggingActions from '~/actions/logging';
import { TrackingFunnelContext } from '~/contexts';

// This function is to allow us to inject props in the `funnel` object when calling the log function (reportException, reportInfo, etc.)
// ex: reportInfo('random message', { funnel: { myAddedFunnelProp: 'myFunnelPropValue' } });
// will add `myAddedFunnelProp` to the `funnel` object alongside `name`, `parents` and `context`
// funnel: {
//   name: somethingSomething,
//   parents: [somethingSomething, somethingElse],
//   context: {},
//   myAddedFunnelProp: 'myFunnelPropValue',
// }
function getEnrichedFunnelWithContext(logContext, funnel) {
  if (!logContext.funnel) {
    return funnel;
  }

  return { ...funnel, ...logContext.funnel };
}

export function useLogger() {
  const dispatch = useDispatch();
  const { name, parents = [], sharedProperties: sharedProps = {} } = useContext(TrackingFunnelContext);

  // WARNING: react-hooks/exhaustive-deps has been disabled because the `funnel` and `sharedProps` variables are objects and therefore
  // never equal across renders since the objects are reconstructed and it is their references in memory that are compared.
  // Nonetheless, trying to get the best of both worlds between stale reference and performance by adding their stringified versions to
  // the dependency array, as suggested by Dan Abramov here (solution 3): https://github.com/facebook/react/issues/14476#issuecomment-471199055
  //
  // ⚠️⚠️⚠️⚠️ BE MINDFUL THAT IF YOU CHANGE THE USEMEMO THE LINTER WON'T YELL AT YOU TO ADD THE NEW DEPENDENCIES TO THE DEPENDENCY ARRAY!!
  // ️⚠️⚠️⚠️⚠️ YOU WILL NEED TO ADD IT YOURSELF!!
  //
  // Why using a memoized `funnelContext` instead of having the stringified version of `funnel` and `sharedProps` in the useCallback's
  // dependency array? The goal was to isolate as much as possible the bad practice of misusing the dependency array. It is most likely
  // that if this hook needs updating, it will be in the callback that's returned so the idea was to keep it "clean" and not ignore the
  // dependency array.
  const funnel = useMemo(
    () => ({ name, parents, context: sharedProps }),
    [name, JSON.stringify(parents), JSON.stringify(sharedProps)],
  );

  const reportException = useCallback(
    (error, errorContext = {}) =>
      dispatch(
        loggingActions.reportException(error, {
          ...errorContext,
          funnel: getEnrichedFunnelWithContext(errorContext, funnel),
        }),
      ),
    [dispatch, funnel],
  );
  const reportInfo = useCallback(
    (message, messageContext = {}) =>
      dispatch(
        loggingActions.reportInfo(message, {
          ...messageContext,
          funnel: getEnrichedFunnelWithContext(messageContext, funnel),
        }),
      ),
    [dispatch, funnel],
  );
  const reportWarning = useCallback(
    (message, messageContext = {}) =>
      dispatch(
        loggingActions.reportWarning(message, {
          ...messageContext,
          funnel: getEnrichedFunnelWithContext(messageContext, funnel),
        }),
      ),
    [dispatch, funnel],
  );

  return useMemo(() => ({ reportException, reportInfo, reportWarning }), [reportException, reportInfo, reportWarning]);
}
