import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { Alert, FieldsFilters, Flex, Link, tokens } from '@unitoio/mosaic';

import {
  StartStopSyncingHeader,
  StartStopSyncingHeaderVariant,
} from '../StartStopSyncingHeader/StartStopSyncingHeader';
import { useGetSides } from '../../Rules/hooks';
import * as formUtils from '../../../utils/form';
import { SiteAdminContext } from '~/contexts';

export function useSetStopSyncing(side) {
  const { setValue } = useFormContext();
  const stopSyncingPath = `rules.${side}.filters.stopSyncing`;
  const stopSyncingValue = useWatch({ name: stopSyncingPath });
  return [
    stopSyncingValue,
    useCallback(
      (value) => {
        setValue(stopSyncingPath, value, { shouldDirty: true });
      },
      [setValue, stopSyncingPath],
    ),
  ];
}

export const StopSyncing = ({ side, loadingState }) => {
  const isAutoSaving = loadingState === formUtils.loadingStates.SAVING;

  const { [side]: currentSide } = useGetSides();
  const [stopSyncingValue, setStopSyncing] = useSetStopSyncing(side);
  const { providerIdentityId, containerPath, itemType } = currentSide;

  // temporary display for users
  const { isSiteAdmin } = useContext(SiteAdminContext);

  return (
    <>
      <Alert type="info">
        This feature is currently in beta. The stop syncing conditions are automatically set as the opposite of your
        start syncing conditions. Please let us know
        <Link
          style={{ fontSize: tokens.fontSize.f7 }}
          isExternalLink
          href="https://form.typeform.com/to/IHxorYPP#id={{id}}&email={{email}}&ws_id={{ws_id}}"
        >
          if you have feedback here
        </Link>
        .
      </Alert>
      {/* Only avilable for siteAdmins for the duration of the A/B test */}
      {isSiteAdmin && (
        <Flex vertical gap={tokens.spacing.s4} style={{ marginTop: tokens.spacing.s4 }}>
          <StartStopSyncingHeader side={side} variant={StartStopSyncingHeaderVariant.StopSyncing} />
          <FieldsFilters
            firstRowPrefix="IF"
            combinator="or"
            disabled={isAutoSaving}
            credentialId={providerIdentityId}
            containerPath={containerPath}
            onChange={setStopSyncing}
            value={stopSyncingValue}
            itemType={itemType}
          />
        </Flex>
      )}
    </>
  );
};

StopSyncing.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
