import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { NewButton } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import { getIsSyncInProgress } from 'reducers';
import * as linkActions from '~/actions/links';
import { useGetQueryParamString } from '~/hooks';

export const SyncNowButton = ({ link }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isSyncInProgress = useSelector((state) => getIsSyncInProgress(state, link.key));

  const queryParamString = useGetQueryParamString();

  const buildFlowEditRoute = (linkId) => `${routes.getEditFlowBuilderRoute(linkId)}${queryParamString}`;

  const handleOnClick = () => {
    if (link.state === linkTypes.LINK_STATES.DRAFT) {
      return history.push(buildFlowEditRoute(link.key));
    }
    // TODO: currently crashes the page because there's a bug in the way we load links into the parent component
    return dispatch(linkActions.syncLink(link.key));
  };

  return (
    <NewButton
      onClick={handleOnClick}
      loading={isSyncInProgress}
      disabled={![linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.ACTIVE].includes(link.state) || isSyncInProgress}
      size="small"
    >
      {link.state === linkTypes.LINK_STATES.DRAFT ? 'Edit draft' : 'Sync now'}
    </NewButton>
  );
};

SyncNowButton.propTypes = {
  link: PropTypes.shape({
    state: PropTypes.oneOf([linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.ACTIVE]),
    key: PropTypes.string,
  }),
};

// abstraction from the antD specs for a table render
export const SyncNowButtonRenderer = (_text_, link) => <SyncNowButton link={link} />;
