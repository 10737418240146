import { useLocation } from 'react-router';

export function useQueryParams() {
  const queryParams = new URLSearchParams(useLocation().search);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent#Decoding_query_parameters_from_a_URL
  const decodeQueryParam = (value) => decodeURIComponent(value.replace(/\+/g, ' '));

  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    try {
      queryParamsObject[key] = decodeQueryParam(value);
    } catch {
      queryParamsObject[key] = value;
    }

    try {
      queryParamsObject[key] = JSON.parse(queryParamsObject[key]);
    } catch {}
  });

  return queryParamsObject;
}

export function useGetQueryParamString() {
  const params = useQueryParams();
  const queryParams = new URLSearchParams(params).toString();
  return queryParams ? `?${queryParams}` : '';
}
