import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FlowBuilderErrorContext } from '~/contexts';
import { getFeatureFlagValue } from '~/reducers';
import * as featureTypes from '~/consts/features';

export const INCOMPLETE_ACTIONS_A_ERROR = 'incompleteActionsA';
export const INCOMPLETE_ACTIONS_B_ERROR = 'incompleteActionsB';

export function useGetIncompleteActions() {
  const hasAccessToModernRules = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE),
  );

  const { setError, clearErrors, formState } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const [actionsA, actionsB] = useWatch({ name: ['rules.A.actions', 'rules.B.actions'] });

  // we don't want to trigger an error when the user selects a field. There should only be an error if
  // a field was chosen, but no value was associated to it
  const incompleteActionsA = !!formState?.errors?.rules?.A?.actions || actionsA?.some((action) => !action.value);
  const incompleteActionsB = !!formState?.errors?.rules?.B?.actions || actionsB?.some((action) => !action.value);

  useEffect(() => {
    if (!hasAccessToModernRules) {
      return;
    }

    const errorNameA = `${pageName}.${INCOMPLETE_ACTIONS_A_ERROR}`;
    if (incompleteActionsA) {
      setError(errorNameA, { type: 'manual' });
    } else {
      clearErrors(errorNameA);
    }

    const errorNameB = `${pageName}.${INCOMPLETE_ACTIONS_B_ERROR}`;
    if (incompleteActionsB) {
      setError(errorNameB, { type: 'manual' });
    } else {
      clearErrors(errorNameB);
    }
  }, [clearErrors, incompleteActionsA, incompleteActionsB, pageName, setError, hasAccessToModernRules]);

  return [incompleteActionsA, incompleteActionsB];
}
