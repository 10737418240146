import { DefaultPortModel } from '@projectstorm/react-diagrams';
import { EdgeModel } from '../Edges/EdgeModel';

export class FlowPortModel extends DefaultPortModel {
  constructor(options) {
    super({
      ...options,
      type: 'FlowPort',
      name: 'FlowPort',
    });

    this.setLocked(true);
  }

  createLinkModel = () => {
    const link = new EdgeModel();
    return link;
  };
}
