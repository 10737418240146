import { PortModel } from '@projectstorm/react-diagrams';
import { EdgeModel } from '../Edges/EdgeModel';

export class WorkBlockPortModel extends PortModel {
  constructor(alignment) {
    super({
      type: 'WorkBlockPort',
      name: alignment,
      alignment,
    });
  }

  createLinkModel = () => {
    const edge = new EdgeModel();
    edge.registerListener({
      eventDidFire: (e) => {
        try {
          if (e.function === 'selectionChanged' && e.isSelected) {
            edge.setPoints([edge.getFirstPoint(), edge.generatePoint(this.getPosition().x, this.getPosition().y)]);
          }
        } catch {
          // eslint-disable-next-line no-console
          console.log('Error trying to generate link position');
        }
      },
    });
    return edge;
  };
}
