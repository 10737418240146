import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { useLogger } from '~/hooks/useLogger';
import * as fieldActions from '~/actions/fields';
import * as fieldTypes from '~/consts/fields';
import * as pcdFilterOperatorTypes from '~/consts/pcdFilterOperator';

import { getDefautValuesToSetBySide } from '../utils';
import { statuses } from '../consts';

export function useSetDefaultFieldValues({
  formSides,
  isDraft,
  loadingState,
  providerFieldsA: pcdFieldsA,
  providerFieldsB: pcdFieldsB,
  areProvidersCapabilitiesForItemsLoaded,
  itemTypeA,
  itemTypeB,
  containerTypeA,
  containerTypeB,
}) {
  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const { setValue } = useFormContext();
  const [status, setStatus] = useState(statuses.STANDBY);
  const [isFetching, setIsFetching] = useState(false);
  const {
    A: {
      actions: { fields: fieldsA },
    },
    B: {
      actions: { fields: fieldsB },
    },
  } = formSides;

  const defaultValuesToSetA = getDefautValuesToSetBySide(pcdFieldsA);
  const defaultValuesToSetB = getDefautValuesToSetBySide(pcdFieldsB);
  const requiresSetDefaultA = !defaultValuesToSetA.isEmpty() && fieldsA?.length === 0;
  const requiresSetDefaultB = !defaultValuesToSetB.isEmpty() && fieldsB?.length === 0;
  const requiresSetDefault = requiresSetDefaultA || requiresSetDefaultB;

  const shouldInitializeDefaultFieldValuesForA = isDraft && requiresSetDefaultA;
  const shouldInitializeDefaultFieldValuesForB = isDraft && requiresSetDefaultB;
  const shouldInitializeDefaultFieldValues =
    shouldInitializeDefaultFieldValuesForA || shouldInitializeDefaultFieldValuesForB;

  useEffect(() => {
    async function fetchDefaultFieldValue(fieldId, pcdField, side) {
      const kind = fieldTypes.KINDS.PCD_TYPED_FIELD;
      const itemType = side === 'A' ? itemTypeA : itemTypeB;
      const containerType = side === 'A' ? containerTypeA : containerTypeB;
      const { defaultFieldValue } = await dispatch(
        fieldActions.fetchFieldValues({
          containerId: formSides[side].containerId,
          containerSide: side,
          fieldId,
          kind,
          providerIdentityId: formSides[side].providerIdentityId,
          filters: formSides[side].filters,
          fetchDefault: true,
          itemType,
          containerType,
        }),
      );

      return {
        _id: `default-${fieldId}`,
        kind,
        value: defaultFieldValue.id,
        isSetDefault: true,
        fieldId,
        operator: pcdFilterOperatorTypes.pcdFilterOperator.EQ,
        label: `Set ${pcdField.getIn(['names', 'singular'], '').toLowerCase()} to`,
        type: pcdField.get('type'),
      };
    }

    async function fetchDefaultFieldValues() {
      if (!areProvidersCapabilitiesForItemsLoaded) {
        return status;
      }

      if (!shouldInitializeDefaultFieldValues || !requiresSetDefault) {
        setStatus(statuses.SUCCESS_DFV);
        return status;
      }

      if (shouldInitializeDefaultFieldValues && status === statuses.STANDBY) {
        setStatus(statuses.INITIAL);
      }
      if (status === statuses.INITIAL && !isFetching) {
        try {
          setStatus(statuses.LOADING);
          let defaultFieldValuesA = fieldsA ?? [];
          let defaultFieldValuesB = fieldsB ?? [];
          setIsFetching(true);

          if (shouldInitializeDefaultFieldValuesForA && shouldInitializeDefaultFieldValuesForB) {
            const promises = [
              Promise.all(
                defaultValuesToSetA
                  .map((pcdField, fieldId) => fetchDefaultFieldValue(fieldId, pcdField, 'A'))
                  .toArray(),
              ),
              Promise.all(
                defaultValuesToSetB
                  .map((pcdField, fieldId) => fetchDefaultFieldValue(fieldId, pcdField, 'B'))
                  .toArray(),
              ),
            ];

            [defaultFieldValuesA, defaultFieldValuesB] = await Promise.all(promises);
          } else if (shouldInitializeDefaultFieldValuesForA) {
            defaultFieldValuesA = await Promise.all(
              defaultValuesToSetA.map((pcdField, fieldId) => fetchDefaultFieldValue(fieldId, pcdField, 'A')).toArray(),
            );
          } else {
            defaultFieldValuesB = await Promise.all(
              defaultValuesToSetB.map((pcdField, fieldId) => fetchDefaultFieldValue(fieldId, pcdField, 'B')).toArray(),
            );
          }

          setValue('A.actions', defaultFieldValuesA, { shouldDirty: true });
          setValue('B.actions', defaultFieldValuesB, { shouldDirty: true });
          setStatus(statuses.SUCCESS_DFV);
        } catch (err) {
          reportException(err);
          setStatus(statuses.ERROR_DFV);
        } finally {
          setIsFetching(false);
        }
      }

      return status;
    }

    fetchDefaultFieldValues();
  }, [
    areProvidersCapabilitiesForItemsLoaded,
    containerTypeA,
    containerTypeB,
    defaultValuesToSetA,
    defaultValuesToSetB,
    dispatch,
    itemTypeA,
    itemTypeB,
    loadingState,
    reportException,
    setValue,
    shouldInitializeDefaultFieldValues,
    shouldInitializeDefaultFieldValuesForA,
    shouldInitializeDefaultFieldValuesForB,
    status,
  ]);

  return status;
}
