import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Switch } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import { linkActions } from '~/actions';

export const AutoSyncToggle = ({ link: { isAutoSync, key: id, state } }) => {
  const dispatch = useDispatch();

  const handleOnAutoSyncToggle = useCallback(
    (linkId) => {
      if (isAutoSync) {
        return dispatch(linkActions.setManualSyncLink(linkId));
      }
      return dispatch(linkActions.setAutoSyncLink(linkId));
    },
    [dispatch, isAutoSync],
  );

  return (
    <Switch
      onChange={() => handleOnAutoSyncToggle(id)}
      value={isAutoSync}
      disabled={state === linkTypes.LINK_STATES.DRAFT}
    />
  );
};

AutoSyncToggle.propTypes = {
  link: PropTypes.shape({
    state: PropTypes.oneOf([linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.ACTIVE]),
    key: PropTypes.string,
    isAutoSync: PropTypes.bool,
  }),
};

// abstraction from the antD specs for a table render
export const AutoSyncToggleRenderer = (_text_, link) => <AutoSyncToggle link={link} />;
