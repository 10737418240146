import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Space, tokens, NewButton as Button, ProviderIcon } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';
import * as linkTypes from '~/consts/link';
import { SyncDirectionIcon } from '~/components/SyncDirectionIcon/SyncDirectionIcon';

export const SyncDirectionIcons = ({ link }) => {
  const history = useHistory();
  const editFlowRoute = routes.getEditFlowBuilderRoute(link.key);

  return (
    <Button
      size="large"
      ghost
      style={{ height: 50, border: 'none' }}
      onClick={() => {
        history.push(editFlowRoute);
      }}
    >
      <Space>
        <ProviderIcon name={link?.A?.providerName} />
        <SyncDirectionIcon
          color={tokens.colors.content.secondary.default}
          readOnlyA={link?.syncSettings?.A?.readOnly}
          readOnlyB={link?.syncSettings?.B?.readOnly}
        />
        <ProviderIcon name={link?.B?.providerName} />
      </Space>
    </Button>
  );
};

SyncDirectionIcons.propTypes = {
  link: PropTypes.shape({
    state: PropTypes.oneOf([linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.ACTIVE]),
    key: PropTypes.string,
    isAutoSync: PropTypes.bool,
    A: PropTypes.shape({
      providerName: PropTypes.string,
    }),
    B: PropTypes.shape({
      providerName: PropTypes.string,
    }),
    syncSettings: PropTypes.shape({
      A: PropTypes.shape({
        readOnly: PropTypes.bool,
      }),
      B: PropTypes.shape({
        readOnly: PropTypes.bool,
      }),
    }),
  }),
};

// abstraction from the antD specs for a table render
export const SyncDirectionIconsRenderer = (_text_, link) => <SyncDirectionIcons link={link} />;
