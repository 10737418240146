import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Flex, FieldsFilters, tokens, buildOppositeMQLQuery } from '@unitoio/mosaic';

import {
  StartStopSyncingHeader,
  StartStopSyncingHeaderVariant,
} from '../StartStopSyncingHeader/StartStopSyncingHeader';
import { useGetSides } from '../../Rules/hooks';
import * as formUtils from '../../../utils/form';
import { useGetDefaultTestModeDate } from '../../Rules/RulesSection/ClassicIntegrationRules/utils';
import { useSelector } from 'react-redux';
import { getFeatureFlagValue } from '~/reducers';
import * as featureTypes from '~/consts/features';

export function useSetStartSyncing(side) {
  const { setValue } = useFormContext();
  const startSyncingPath = `rules.${side}.filters.startSyncing`;
  const stopSyncingPath = `rules.${side}.filters.stopSyncing`;
  const sideFiltersInitializedAt = `${side}.filtersInitializedAt`;
  const [startSyncingValue, sideFiltersInitializedAtValue] = useWatch({
    name: [startSyncingPath, sideFiltersInitializedAt],
  });
  return [
    startSyncingValue,
    useCallback(
      (value) => {
        setValue(startSyncingPath, value, { shouldDirty: true });
        // create and set the opposite query in stopSyncing
        setValue(stopSyncingPath, buildOppositeMQLQuery(value, 'and', 'or'));
        // create and set filtersInitializedAt on any change if it wasn't already set
        if (!sideFiltersInitializedAtValue) {
          setValue(sideFiltersInitializedAt, Date.now());
        }
      },
      [setValue, startSyncingPath, stopSyncingPath, sideFiltersInitializedAtValue, sideFiltersInitializedAt],
    ),
  ];
}

export const NULL_FILTER_VALUE = [null, '', []];

const isEmptyFilterValue = (filterValue) =>
  filterValue &&
  Array.isArray(filterValue) &&
  filterValue.every((value) => NULL_FILTER_VALUE.includes(value) || (Array.isArray(value) && value.length === 0));

export const isEmptyMQLFilter = (filters) =>
  // check if filters is nullish or filters === { $and: [{ $expr: true }] }
  // but because JS is having a hard time comparing filters directly with { $and: [{ $expr: true }] }
  // we're deconstructing the object below to compare
  !filters ||
  (Object.keys(filters).length === 1 &&
    Array.isArray(filters.$and) &&
    filters.$and.length === 1 &&
    Object.values(filters.$and)[0].$expr === true);

/* filters can have one of the following shapes:
 * // no filters, or last filter removed
 *  {
 *    $and: [{ $expr: true }]
 *  }
 *
 *  // exactly one filter
 *  {
 *    "labels.path": {
 *      "$in": [
 *        "/enums/labels/6126a1ced0ddddc18b5244ae"
 *     ]
 *    }
 *  }
 *
 * // multiple filters
 *  {
 *    "$and": [
 *      {
 *        "labels.path": {
 *          "$in": [
 *            "/enums/labels/6126a1ced0ddddc18b5244ae"
 *          ]
 *        }
 *      },
 *      {
 *        "author.path": {
 *          "$in": [
 *            "/enums/author/5efa52ca64b08f4eb5ee1c11"
 *          ]
 *        }
 *      },
 *      {
 *        "title": {
 *          "$includes": "test"
 *        }
 *      }
 *    ]
 *  }
 *  */
export const useAddActionFromFilters = (side) => {
  const watchedActions = useWatch({ name: `rules.${side}.actions` });
  const { append, update } = useFieldArray({ name: `rules.${side}.actions` });
  return useCallback(
    (filters) => {
      if (isEmptyMQLFilter(filters)) {
        return;
      }
      const addActionFromFilter = (filter) => {
        Object.entries(filter).forEach(([fieldName, operandAndValue]) => {
          const operand = Object.keys(operandAndValue)[0];
          // only generate default values for filters of type list for now
          if (operand !== '$in') {
            return;
          }
          const actionName = fieldName.split('.')[0];
          const filterValue = Object.values(operandAndValue)[0];
          // the 'is empty' filter is also a list type filter but we don't want to add it as an action
          if (isEmptyFilterValue(filterValue)) {
            return;
          }
          // apply the first array value as was done previously in the backend.
          // e.g. if the filter has labels: ['red', 'green', 'blue'], we will only apply label 'red'
          const actionValue = Array.isArray(filterValue) ? [{ path: filterValue[0] }] : filterValue;
          // use index because we might need it for useFieldArray's update method in case
          const existingActionIndex = watchedActions.findIndex((action) => action.fieldName === actionName);
          const actionMissing = existingActionIndex === -1;
          let actionValueAlreadySet = false;
          if (!actionMissing) {
            actionValueAlreadySet = Array.isArray(watchedActions[existingActionIndex].value)
              ? watchedActions[existingActionIndex].value.length > 0
              : watchedActions[existingActionIndex].value !== null;
          }

          if (
            /* This condition uses the watchedActions rather than the 'fields' from useFieldArray
             * because of a bug with uesFieldArray's fields field. If useFieldArray#remove is called elsewhere
             * in the app on the same field array key, useFieldArray's fields won't always detect it
             * / be up-to-date, so falling back on watched values instead solves this issue. */
            actionMissing
          ) {
            append({
              fieldName: actionName,
              value: actionValue,
              type: 'setValue',
              trigger: 'start',
            });
          } else if (!actionMissing && !actionValueAlreadySet) {
            // if action was already added but does not have a value, set it based on the filter
            update(existingActionIndex, {
              fieldName: actionName,
              value: actionValue,
              type: 'setValue',
              trigger: 'start',
            });
          }
        }, {});
      };
      Object.entries(filters).forEach(([key, value]) => {
        // if multi filter case, go through each
        if (Array.isArray(value)) {
          value.forEach((filter) => {
            addActionFromFilter(filter);
          });
        } else {
          // other treat as single filter/ generic object
          addActionFromFilter({ [key]: value });
        }
      });
    },
    [append, update, watchedActions],
  );
};

export const useGetDisabledValues = () => {
  const canRemoveCreatedAt = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.CAN_MODIFY_CREATED_AT),
  );
  return canRemoveCreatedAt ? [] : ['createdAt'];
};

export const StartSyncing = ({ side, loadingState }) => {
  const isAutoSaving = loadingState === formUtils.loadingStates.SAVING;

  const { [side]: currentSide } = useGetSides();
  const [startSyncingValue, setStartSyncing] = useSetStartSyncing(side);
  const appendDefaultValuesFromFilters = useAddActionFromFilters(side);
  const { providerIdentityId, containerPath, itemType } = currentSide;
  const defaultTestModeDate = useGetDefaultTestModeDate();
  const sideFiltersInitializedAt = `${side}.filtersInitializedAt`;
  const filtersInitializedAt = useWatch({
    name: sideFiltersInitializedAt,
  });

  const disabledValues = useGetDisabledValues();

  return (
    <Flex vertical gap={tokens.spacing.s4}>
      <StartStopSyncingHeader side={side} variant={StartStopSyncingHeaderVariant.StartSyncing} />
      <FieldsFilters
        initialValues={[
          {
            semantic: 'createdAt',
            value: defaultTestModeDate?.toISOString() ?? Date.now(),
            operator: '>=',
          },
        ]}
        disabledValues={disabledValues}
        shouldInitialize={!filtersInitializedAt}
        firstRowPrefix="IF"
        disabled={isAutoSaving}
        credentialId={providerIdentityId}
        containerPath={containerPath}
        onChange={(filters) => {
          setStartSyncing(filters);
          // note for devs: we add an action on the same side, meaning that it will display
          // in the opposite rule section in the UI. This is the expected behavior.
          appendDefaultValuesFromFilters(filters);
        }}
        value={startSyncingValue}
        itemType={itemType}
      />
    </Flex>
  );
};

StartSyncing.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
