import { message } from '@unitoio/mosaic';

import * as loggingActions from '~/actions/logging';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import { getSelectedOrganizationId } from 'reducers';
import * as localStorage from '~/utils/localStorage';
import * as workflowTypes from '~/consts/workflows';
import * as linkActions from '~/actions/links';

export const getWorkflows = (organizationId) => (dispatch, getState) =>
  dispatch({
    types: [
      workflowTypes.GET_WORKFLOWS_REQUEST,
      workflowTypes.GET_WORKFLOWS_SUCCESS,
      workflowTypes.GET_WORKFLOWS_FAILURE,
    ],
    url: routes.API_PATHS.GET_ORGANIZATION_WORKFLOWS(organizationId || getSelectedOrganizationId(getState())),
  });

export const searchWorkflows = (siteAdminSearchString) => ({
  types: [workflowTypes.GET_WORKFLOW_REQUEST, workflowTypes.GET_WORKFLOWS_SUCCESS, workflowTypes.GET_WORKFLOWS_FAILURE],
  url: routes.API_PATHS.SEARCH_WORKFLOWS(siteAdminSearchString),
});

export const getWorkflow = (workflowId) => ({
  types: [workflowTypes.GET_WORKFLOW_REQUEST, workflowTypes.GET_WORKFLOW_SUCCESS, workflowTypes.GET_WORKFLOW_FAILURE],
  meta: { workflowId },
  url: routes.API_PATHS.GET_WORKFLOW(workflowId),
});

export const saveWorkflow = (workflowId, payload) => (dispatch) => {
  if (!workflowId) {
    // Using local storage to store the user's playground workflow.
    // The reason is we may be able to later leverage this if they sign up.
    const localStorageWorkflow = localStorage.loadStateByKey(workflowTypes.LOCAL_STORAGE_WORKFLOW_KEY) ?? {};
    const updatedWorkflow = { ...localStorageWorkflow, ...payload };
    try {
      localStorage.saveState(workflowTypes.LOCAL_STORAGE_WORKFLOW_KEY, updatedWorkflow);
    } catch (err) {
      // catch, log, fail
      dispatch(
        loggingActions.reportException(err, {
          context: { functionName: 'saveWorkflow saveState' },
        }),
      );
      throw err;
    }
    // Standardize as redux-action by dispatching an action nonetheless
    return dispatch({
      type: workflowTypes.SAVE_PLAYGROUND_WORKFLOW,
      payload,
    });
  }
  return dispatch({
    method: routes.METHODS.PATCH,
    types: [
      workflowTypes.PATCH_WORKFLOW_REQUEST,
      workflowTypes.PATCH_WORKFLOW_SUCCESS,
      workflowTypes.PATCH_WORKFLOW_FAILURE,
    ],
    payload,
    meta: { workflowId },
    url: routes.API_PATHS.PATCH_WORKFLOW(workflowId),
  });
};

export const addBlock = ({ workflowId, providerIdentityId, providerContainerId, itemType, containerType }) => ({
  types: [workflowTypes.ADD_BLOCK_REQUEST, workflowTypes.ADD_BLOCK_SUCCESS, workflowTypes.ADD_BLOCK_FAILURE],
  url: routes.API_PATHS.ADD_BLOCK(workflowId),
  method: routes.METHODS.POST,
  payload: { providerIdentityId, providerContainerId, itemType, containerType },
});

export const deleteBlock = (workflowId, blockId) => ({
  types: [workflowTypes.DELETE_BLOCK_REQUEST, workflowTypes.DELETE_BLOCK_SUCCESS, workflowTypes.DELETE_BLOCK_FAILURE],
  meta: { workflowId, blockId },
  url: routes.API_PATHS.DELETE_BLOCK(workflowId, blockId),
  method: routes.METHODS.DELETE,
});

export const removeFlow = (workflowId, linkId) => ({
  types: [workflowTypes.REMOVE_FLOW_REQUEST, workflowTypes.REMOVE_FLOW_SUCCESS, workflowTypes.REMOVE_FLOW_FAILURE],
  meta: { workflowId, linkId },
  url: routes.API_PATHS.REMOVE_FLOW(workflowId, linkId),
  method: routes.METHODS.DELETE,
});

export const getProviderContainersByWorkflowId = (workflowId) => ({
  types: [
    workflowTypes.GET_PROVIDER_CONTAINERS_BY_WORKFLOW_REQUEST,
    workflowTypes.GET_PROVIDER_CONTAINERS_BY_WORKFLOW_SUCCESS,
    workflowTypes.GET_PROVIDER_CONTAINERS_BY_WORKFLOW_FAILURE,
  ],
  url: routes.API_PATHS.GET_PROVIDER_CONTAINERS_BY_WORKFLOW(workflowId),
});

export const getLinksByWorkflowId = (workflowId) => ({
  types: [
    workflowTypes.GET_LINKS_BY_WORKFLOW_ID_REQUEST,
    workflowTypes.GET_LINKS_BY_WORKFLOW_ID_SUCCESS,
    workflowTypes.GET_LINKS_BY_WORKFLOW_ID_FAILURE,
  ],
  meta: { workflowId },
  url: routes.API_PATHS.GET_LINKS_BY_WORKFLOW_ID(workflowId),
});

export const generateWorkflowResourcesOnDelete = (organizationId, linkIds) => ({
  types: [
    workflowTypes.POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_REQUEST,
    workflowTypes.POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_SUCCESS,
    workflowTypes.POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_FAILURE,
  ],
  payload: { linkIds },
  method: routes.METHODS.POST,
  url: routes.API_PATHS.POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE(organizationId),
});

export const deleteWorkflow = (workflowId) => async (dispatch) => {
  const response = await dispatch({
    types: [
      workflowTypes.DELETE_WORKFLOWS_REQUEST,
      workflowTypes.DELETE_WORKFLOWS_SUCCESS,
      workflowTypes.DELETE_WORKFLOWS_FAILURE,
    ],
    method: routes.METHODS.DELETE,
    url: routes.API_PATHS.DELETE_WORKFLOW(workflowId),
  });
  await dispatch(
    linkActions.getLinks({
      kinds: [linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.REPORT_SYNC, linkTypes.KIND.MULTI_SYNC],
    }),
  );
  return response;
};

export const createWorkflow = (setIsCreatingWorkflow, history, organizationId) => async (dispatch) => {
  try {
    setIsCreatingWorkflow(true);
    const { workflow } = await dispatch({
      types: [
        workflowTypes.CREATE_WORKFLOW_REQUEST,
        workflowTypes.CREATE_WORKFLOW_SUCCESS,
        workflowTypes.CREATE_WORKFLOW_FAILURE,
      ],
      url: routes.API_PATHS.CREATE_WORKFLOW(organizationId),
      method: routes.METHODS.POST,
    });
    setIsCreatingWorkflow(false);
    history.push({ pathname: `${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${workflow._id}` });
    return workflow;
  } catch {
    message.error({
      content: 'Oops something went wrong while creating your workflow.',
    });
  }
  return {};
};
