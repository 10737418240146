const TEMPLATES = {
  InvalidRequestError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: [
        'Resolve any warnings you may have in your flow and try again. If the error persists, contact our support team.',
      ],
    },
  },
  CannotUpdateTaskError: {
    label: 'We were unable to update your item.',
    solutions: {
      title: 'Solutions',
      context: [
        'This is often caused by not having required fields present (description, for example), or the destination item being in an inaccessible state (a closed sprint, for example). Ensure that your items meet the requirements for Unito to access and update them in your tool.',
      ],
    },
  },
  ConnectorError: {
    label: 'Your integration could not be contacted or has returned an error.',
    solutions: {
      title: 'Solutions',
      context: [
        'Unito will retry your request automatically. You may want to check that your flow has no errors and your tools are authorized properly. Sometimes downtime from the tool providers can also cause this error, in which case it will resolve itself automatically.',
      ],
    },
  },
  CannotCreateTaskError: {
    label: 'We were unable to create a item.',
    solutions: {
      title: 'Solutions',
      context: [
        'This is often caused when required fields are missing content (start or end date, for example). Ensure that your items meet the requirements for Unito to access and create them in your tool.',
      ],
    },
  },
  InvalidResponseError: {
    label: 'We received an invalid response that prevented us from processing the request.',
    solutions: {
      title: 'Solutions',
      context: [
        "If possible, we'll automatically retry this request. Check that you don't have any other issues in your flow. If the error persists, contact our support team.",
      ],
    },
  },
  TaskNotFoundError: {
    label: 'The item cannot be found or does not exist.',
    solutions: {
      title: 'Solutions',
      context: [
        'This occurs when a item has been deleted or moved. Either restore the item or move it back to the original container.',
      ],
    },
  },
  FormattingError: {
    label: 'A formatting error occurred.',
    solutions: {
      title: 'Solutions',
      context: [
        'If possible, we will automatically retry your request. Otherwise verify that the formatting of the fields you have mapped and their contents is correct.',
      ],
    },
  },
  TranslationToNativeError: {
    label: 'Error translating the work item into native format.',
    solutions: {
      title: 'Solutions',
      context: [
        'If possible, we will automatically retry your request. Ensure the format of your destination field matches that of your source field.',
      ],
    },
  },
  ConfigurationError: {
    label: "Something isn't configured correctly that prevented us from making an update.",
    solutions: {
      title: 'Solutions',
      context: [
        'Ensure that the correct settings are applied to your destination container. A common example is a calendar that is set to read-only. Then retry syncing.',
      ],
    },
  },
  SubTaskDepthExceededError: {
    label: 'This tool does not support deep subtask structures.',
    solutions: {
      title: 'Solutions',
      context: [
        'Unito only supports syncing a single level of sub-items. Please submit a [feature request](https://unito.canny.io/feature-requests) if you would like to have more supported.',
      ],
    },
  },
  PermissionDeniedError: {
    label:
      'It appears that you do not have the necessary permissions to perform the requested action. We were denied access to the tool.',
    solutions: {
      title: 'Solutions',
      context: [
        'Check the [required permissions](https://guide.unito.io/every-user-permission-unito-needs) for your tool.',
        'If you believe you should have access, please contact your system administrator or contact our support team for assistance.',
      ],
    },
  },
  UserNotFoundError: {
    label: 'User not found.',
    solutions: {
      title: 'Solutions',
      context: ['Ensure that the user exists in the organization.'],
    },
  },
  AuthenticationError: {
    label: 'Invalid authentication credentials.',
    solutions: {
      title: 'Solutions',
      context: ['Try reauthorizing your tool with Unito, and ensure you have the correct permissions.'],
    },
  },
  DynamoError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: ['We will automatically retry this request.'],
    },
  },
  RateLimitError: {
    label: 'Rate limit reached for the user.',
    solutions: {
      title: 'Solutions',
      context: ['We will automatically retry this request. Wait or try again later at a less busy time.'],
    },
  },
  ClientError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: ['We will automatically retry this request. Wait or try and resync this item.'],
    },
  },
  ConnectionTimeoutError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: ['We will automatically retry this request. Wait or try and resync this item.'],
    },
  },
  ProviderError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: ['We will automatically retry this request. Wait or try and resync this item.'],
    },
  },
  AuthorizationError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: [
        'Try reauthorizing your tool with Unito, and ensure you have the correct permissions. Then try to resync. If the problem persists, contact support.',
      ],
    },
  },
  ConnectionError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: [
        'Try reauthorizing your tool with Unito, and ensure you have the correct permissions. Then try to resync. If the problem persists, contact support.',
      ],
    },
  },
  TimeoutError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: ['We will automatically retry this request. Wait or try and resync this item.'],
    },
  },
  FieldValueNotFoundError: {
    label: 'There was an error updating one of your fields.',
    solutions: {
      title: 'Solutions',
      context: ['Ensure that the item being updated has all the correct fields present. Then try resyncing the item.'],
    },
  },
  ProviderInstanceGoneError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: [
        'Ensure that your flow has no errors, and then try resyncing. If the problem persists, contact support.',
      ],
    },
  },
  ConnectorFormatError: {
    label: 'An error has occurred that prevents us from processing this request.',
    solutions: {
      title: 'Solutions',
      context: [
        'Ensure that the source item has all the correct fields present. Then try resyncing. If the problem persists, contact support.',
      ],
    },
  },
  ProviderDownError: {
    label: 'The tool provider is down or experiencing issues.',
    solutions: {
      title: 'Solutions',
      context: [
        "Please check the provider's status page, and try resyncing again later. If the problem persists, contact support.",
      ],
    },
  },
};

export { TEMPLATES as errorCategoryTemplates };
